import { ref, onMounted, watch, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import QuarantineMeta from "@/models/QuarantineMeta.js"
import QuarantineRow from "@/components/QuarantineRow/index.js"
import Pagination from "@/components/Pagination/index.js"
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import { trans } from "@/plugins/i18n.js"
import {getCookie, setCookie} from "@/plugins/cookie.js"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: [],
    emits: [],
    components: { QuarantineRow, Pagination },
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        let search_debounce = 0;
        const order_by = ref("timestamp")
        const order = ref("desc")
        const page = ref(1)
        const pages = ref(1)
        const total_count = ref(0)
        const limit = ref(-1) // first limit set must trigger update
        const entries = ref([]);
        const search = ref("");
        const search_type = ref("begins_with")
        const search_field = ref("sender")
        const search_flag = ref("")
        const selection_action = ref("")
        const datetime_from = ref("")
        const datetime_to = ref("")
        const selection = ref([])

        const account = computed(function () {
            return store.current_user.account;
        })

        const assets_url = computed(function(){
            return store.assets_url
        })

        const executeSelectionAction = async function(evt){
            evt.preventDefault()
            if (0 == selection.value.length || "" == selection_action.value){
                return
            }
            if ("delete" == selection_action.value){
                if (confirm(`${selection.value.length} ${trans('quarantine_row_actions.delete_label')}?`)){
                    for (var i=0; i<selection.value.length; i++){
                        let entryId = selection.value[i]
                        let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${entryId}`
                        await api("DELETE", url, {})
                    }
                    resetAndLoad()
                }
            }
        }

        const load = async function (show_loader=true) {
            store.error = ""
            if (search_debounce) {
                clearTimeout(search_debounce)
            }
            search_debounce = setTimeout(async function () {
                if (show_loader){
                    store.loading = true
                }
                let url = `/accounts/${encodeURIComponent(account.value)}/quarantine?page=${page.value}&per_page=${limit.value}`
                let search_string = search.value.trim()
                if (2 <= search_string.length){
                    url += `&${search_field.value}_${search_type.value}=${encodeURIComponent(search_string)}`
                }
                url += `&order_by=${order_by.value}&order=${order.value}`
                if (datetime_from.value){
                    url+= `&datefrom=${encodeURIComponent(datetime_from.value)}`
                }
                if (datetime_to.value){
                    url+= `&datefrom=${encodeURIComponent(datetime_to.value)}`
                }
                if( search_flag.value == "flag_delete"){
                    url+= `&flag_delete=1`
                } else if ( search_flag.value == "flag_notread"){
                    url+= `&flag_read=0`
                } else if ( search_flag.value == "flag_notdownload"){
                    url+= `&flag_download=0`
                } else if ( search_flag.value == "flag_download"){
                    url+= `&flag_download=1`
                } else if ( search_flag.value == "flag_notforward"){
                    url+= `&flag_forward=0`
                } else if ( search_flag.value == "flag_forward"){
                    url+= `&flag_forward=1`
                }
                try {
                    let res = await api("GET", url);
                    let resp = await res.json();
                    total_count.value = resp.pagination.total_count;
                    pages.value = resp.pagination.pages;
                    let new_list = [];
                    for (var i = 0; i < resp.data.length; i++) {
                        let entry = new QuarantineMeta();
                        Object.assign(entry, resp.data[i]);
                        entry.homogenize();
                        new_list.push(entry);
                    }
                    entries.value = new_list;
                } catch (err) {
                    store.error = err
                }
                store.loading = false;
            }, 35)
        }
        const removeFromList = function(entry){
            selection.value = []
            selection_action.value = ""
            let new_list = entries.value.filter(function(item){
                return item.id != entry.id
            })
            entries.value = new_list;
        }
        const resetAndLoad = function (show_loader = true) {
            selection.value = []
            selection_action.value = ""
            if (page.value > 1) {
                page.value = 1
            } else {
                load(show_loader)
            }
        }
        const setOrderBy = function(name){
            if (order_by.value == name){
                order.value = 'desc' == order.value ? 'asc' : 'desc'
            } else {
                order_by.value = name
            }
            selection.value = []
            selection_action.value = ""
            load()
        }
        const toggleSelection = function(){
            if (0 == selection.value.length){
                selection.value = entries.value.map(function(item){
                    return item.id
                })
            } else {
                selection.value = []
                selection_action.value = ""
            }
        }

        const onSelectionChanged = function(entryId, selected){
            let new_selection = [...selection.value] // clone
            
            if (!selected){
                new_selection = new_selection.filter(function(item){
                    return item != entryId
                })
            } else {
                new_selection.push(entryId)
            }
            selection.value = new_selection
        }

        onMounted(async function(){
            let newlimit = 10
            let limitCookie = getCookie("antispam_quarantine_limit")
            if (limitCookie){
                newlimit = parseInt(limitCookie)
            }
            limit.value = newlimit;
            
        });

        watch(limit, function () {
            setCookie("antispam_quarantine_limit", limit.value, 10)
            resetAndLoad()
        })
        watch(search, function () {
            resetAndLoad(false)
        })
        watch(search_type, function () {
            resetAndLoad()
        })
        watch(datetime_from, function () {
            resetAndLoad()
        })
        watch(datetime_to, function () {
            resetAndLoad()
        })
        watch(search_field, function () {
            resetAndLoad()
        })
        watch(search_flag, function () {
            resetAndLoad()
        })
        watch(page, function () {
            load()
        })
        

        return {
            assets_url,
            executeSelectionAction,
            datetime_from,
            datetime_to,
            trans,
            entries,
            limit,
            order,
            order_by,
            page,
            pages,
            removeFromList,
            search,
            search_type,
            search_field,
            setOrderBy,
            search_flag,
            selection,
            selection_action,
            toggleSelection,
            onSelectionChanged,

        }
    }
}