// Conditional Import
import { ref, onMounted, reactive, computed } from "vue"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
export default {
    template: html,
    components: {},
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {
        const loading = computed(function(){
            return store.loading
        })

        return {
            loading,
            trans
        }
    }
    
}