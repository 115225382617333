// Conditional Import
import { ref, onMounted, computed } from "vue"
import store from "@/plugins/store.js"
import {trans, i18n} from "@/plugins/i18n.js"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}


export default {
    props: [],
    emits: [],
    template: html,
    components: {},
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {
        const has_error = computed(function(){
            return store.error != ""
        })

        const error = computed(function(){
            return store.error
        })

        const clearError = function(){
            store.error = ""
        }

        return {
            has_error,
            clearError,
            error,
            trans
        }
        
    },
    
}