import { ref, onMounted, reactive, computed , watch} from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
import Account from "../../models/Account.js"
import CurrentUser from "../../models/CurrentUser.js"
import Pagination from "../../components/Pagination/index.js"


export default {
    props: [],
    emits: [],
    components: {
        Pagination
    },
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        let search_debounce = 0;
        const entries = ref([]);
        const limit = ref("10")
        const page = ref(1)
        const pages = ref(1)
        const total_count = ref(0)
        const search = ref("")
        const search_type = ref("contains")
        
        const api_key = computed(function(){
            return store.api_key
        })
        const assets_url = computed(function(){
            return store.assets_url
        })

        const load = async function(show_loader=true) {
            store.error=""
            if (search_debounce){
                clearTimeout(search_debounce)
            }
            search_debounce = setTimeout(async function(){
                if (show_loader){
                    store.loading = true
                }
                let url = `/accounts?page=${page.value}&per_page=${limit.value}`
                let search_string = search.value.trim()
                if (2 <= search_string.length){
                    url += `&${search_type.value}=${encodeURIComponent(search_string)}`
                } 
                try {
                    let res = await api("GET", url);
                    let resp = await res.json();
                    total_count.value = resp.pagination.total_count;
                    pages.value = resp.pagination.pages;
                    let new_list = [];
                    for (var i=0; i<resp.data.length; i++){
                        let entry = new Account();
                        Object.assign(entry, resp.data[i]);
                        entry.homogenize();
                        new_list.push(entry);
                    }
                    entries.value = new_list;
                } catch (err) {
                    store.error = err
                }
                store.loading = false
            }, 35)
        }

        const doImpersonateAs  = async function(user){
            let url = `/session/impersonate` 
            let data = {"account": user.account}
            try {
                let response = await api("POST", url, data)
                let content = await response.json()
                if (200 <= response.status && 400 > response.status){
                    await store.impersonateAs(content.access_token)
                    store.current_view = store.current_user_default_view
                    
                }
            } catch(err){
                store.error = err
            }
            
        }

        const isDisabled = function(user){
            return user.account == store.current_user.account
        }

        const resetAndLoad = function(show_loader = true){
            if (page.value > 1){
                page.value = 1
            } else {
                load(show_loader)
            }
        }
             
        watch(search, function(){
            resetAndLoad(false)
        })
        watch(search_type, function(){
            resetAndLoad()
        })
        watch(page, function(){
            load()
        })
        watch(limit, function(){
            resetAndLoad()
        })
        watch(api_key, function(){
            // Load again when a user is impersonated or unimpersonated
            resetAndLoad()
        })
        onMounted(load);

        return {
            assets_url,
            entries,
            limit,
            doImpersonateAs,
            isDisabled,
            page,
            pages,
            trans,
            search,
            search_type
        }
    }
    
}