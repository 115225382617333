import store from "@/plugins/store.js"
import {i18n} from "@/plugins/i18n.js"
import CurrentUser from "../models/CurrentUser";

let getUserByApiKey = async function (api_key) {
    let api_url = store.api_url;
    api_url += "/session/info"
    const response = await fetch(api_url, {
        method: "GET",
        mode: "cors",
        //cache: "no-cache",
        //credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + api_key,
            "Accept-Language": i18n.global.locale.value
        },
        body: null,
    })
    if (200 <= response.status && 400 > response.status){
        let content = await response.json()
        let currentUser = new CurrentUser()
        Object.assign(currentUser, content)
        return currentUser
    } else {
        return null
    } 
}

export { getUserByApiKey};