import { ref, onMounted, reactive, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import QuarantineMeta from "@/models/QuarantineMeta.js"
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import { trans } from "@/plugins/i18n.js"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: ['entry'],
    emits: ['removed'],
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        const sendToAlternate = function (evt) {
            evt.preventDefault();
            return;
        }

        const is_releasing = computed(function(){
            // SPAM-68: nicht blockieren
            return false
            //return props.entry.release_to != null && props.entry.release_to != ""
        })
        const is_deleted = computed(function(){
            return props.entry.flag_delete == 1
        })

        const account = computed(function () {
            return store.current_user.account;
        })

        const doDownload = async function (evt) {
            evt.preventDefault()
            try {
                let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${props.entry.id}/message?download=true`
                let res = await api("GET", url);
                let content_type = res.headers.get('Content-Type')
                let rx = /name=\"([^\"]*)\"/g;
                let arr = rx.exec(content_type);
                let filename = "message.eml"
                if (1 < arr.length) {
                    filename = arr[1];
                }
                let blob = await res.blob();
                const link = document.createElement('a')
                const dl_url = URL.createObjectURL(blob)
                link.href = dl_url
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(dl_url)

            } catch (err) {
                store.error = err
            }

        }

        const doRelease = async function (evt) {
            evt.preventDefault()
            if (!confirm(trans('quarantine_row_actions.send_to_recipient_label') + "?")){
                return
            }
            let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${props.entry.id}/release`
            let response = await api("POST", url, {})
            if (200 <= response.status && 400 > response.status) {
                emit('removed')
            } else {
                let json = await response.json();
                if (json.hasOwnProperty("detail")) {
                    store.error = json.detail;
                } else {
                    store.error = "Bad Request"
                }
            }

        }

        const doDelete = async function (evt) {
            evt.preventDefault()
            if (!confirm(trans('quarantine_row_actions.delete_label') + "?")){
                return
            }
            let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${props.entry.id}`
            let response = await api("DELETE", url, {})
            if (200 <= response.status && 400 > response.status) {
                emit('removed')
            } else {
                let json = await response.json();
                if (json.hasOwnProperty("detail")) {
                    store.error = json.detail;
                } else {
                    store.error = "Bad Request"
                }
            }

        }
        const assets_url = computed(function(){
            return store.assets_url
        })

        return {
            assets_url,
            doRelease,
            doDownload,
            doDelete,
            is_deleted,
            is_releasing,
            trans,
            sendToAlternate
        }
    }
}