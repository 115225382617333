import { ref, onMounted, reactive, computed, watch } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import { trans } from "@/plugins/i18n.js"
import WbEntry from "../../models/WbEntry"
import WblistEntry from '@/components/WblistEntry/index.js'
import Pagination from '@/components/Pagination/index.js'

export default {
    props: ['list_type'],
    components: {
        WblistEntry,
        Pagination
    },
    setup(props, { attrs, slots, emit, expose }) {
        let search_debounce = 0;
        const saved = ref(false);
        const entries = ref([]);
        const order_by = ref("sender")
        const order = ref("asc")
        const page = ref(1);
        const pages = ref(1);
        const limit = ref(10);
        const selection = ref([])
        const search = ref("")
        const search_type = ref("begins_with")
        const total_count = ref(0);
        const new_entry_show = ref(false);
        const new_entry_allowed = computed(function () {
            return 'personal' == props.list_type || 
                ("global" == props.list_type && "admin" == store.current_user.access_level && "domain" == store.current_user.access_scope)
        })
        const account = computed(function () {
            return store.current_user.account;
        })
        const assets_url = computed(function(){
            return store.assets_url
        })
        const is_mobile = computed(function(){
            return store.mobile
        })

        async function load(show_loader = true) {
            store.error = ""
            if (search_debounce) {
                clearTimeout(search_debounce)
            }
            search_debounce = setTimeout(async function () {
                try {
                    if (show_loader){
                        store.loading = true
                    }
                    let url = `/accounts/${encodeURIComponent(account.value)}/wblist?page=${page.value}&per_page=${limit.value}`
                    if ('personal' == props.list_type) {
                        url += `&inherited=false`
                    } else if ('global' == props.list_type) {
                        url += `&inherited=true`
                    } else if ('contacts' == props.list_type){
                        url += `&source=contacts`
                    } else {
                        url += `inherited=false&source=${props.list_type}`
                    }
                    // TODO: einschalten sobald es funktioniert
                    //url += `&order_by=${order_by.value}&order=${order.value}`
                    let search_string = search.value.trim()
                    if (2 <= search_string.length){
                        url += `&sender_${search_type.value}=${encodeURIComponent(search_string)}`
                    } 
                    let res = await api("GET", url);
                    let resp = await res.json();
                    total_count.value = resp.pagination.total_count;
                    pages.value = resp.pagination.pages;
                    let new_list = [];
                    for (var i = 0; i < resp.data.length; i++) {
                        let entry = new WbEntry();
                        Object.assign(entry, resp.data[i]);
                        entry.homogenize();
                        new_list.push(entry);
                    }

                    entries.value = new_list;
                } catch (err) {
                    store.error = err
                }
                store.loading = false;
            }, 35)
        }

        async function reload(show_loader = true) {
            selectionClear()
            await load(show_loader);
        }

        async function save(evt) {
            evt.preventDefault()
            saved.value = true;
            setTimeout(function () {
                saved.value = false;
            }, 2000);
        }

        const selectionToggle = function (id) {
            let new_selection = selection.value
            if (-1 == new_selection.indexOf(id)) {
                new_selection.push(id)
            } else {
                new_selection = new_selection.filter(function (item) {
                    return item != id
                })
            }
            selection.value = new_selection
        }

        const selectionClear = function () {
            selection.value = []
        }

        const selectionRemove = async function () {
            if (!confirm("delete?")) {
                return;
            }
            let account_name = account.value
            if ("global" == props.list_type){
                account_name = `@${store.current_user.domain}`
            }
            for (var i = 0; i < selection.value.length; i++) {
                let id = selection.value[i]
                try {
                    await api("DELETE", `/accounts/${encodeURIComponent(account_name)}/wblist/${id}`, {});
                } catch (err) {

                }
            }
            selectionClear()
            reload()

        }
        const setOrderBy = function(name){
            if (order_by.value == name){
                order.value = 'desc' == order.value ? 'asc' : 'desc'
            } else {
                order_by.value = name
            }
            load()
        }



        onMounted(load);

        watch(props, function () {
            new_entry_show.value = false
            entries.value = []
            total_count.value = 0
            reload()
        })
        watch(limit, function () {
            page.value = 1
            entries.value = []
            total_count.value = 0
            reload()
        })
        watch(page, function () {
            entries.value = []
            total_count.value = 0
            reload()
        })
        watch(search, function () {
            total_count.value = 0
            reload(false)
        })
        watch(search_type, function () {
            total_count.value = 0
            reload(false)
        })
        watch(new_entry_show, function() {
            selectionClear()
        })


        return {
            assets_url,
            entries,
            order_by,
            order,
            save,
            selectionToggle,
            selectionRemove,
            selectionClear,
            search,
            search_type,
            trans,
            reload,
            total_count,
            is_mobile,
            limit,
            page,
            pages,
            selection,
            setOrderBy,
            new_entry_show,
            new_entry_allowed
        }
    },
    template: html,
}