// Conditional Import
import { ref, onMounted, reactive } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
//let html = eval("HTML_" + "IWAY")
import store from "@/plugins/store.js"
import { setCookie } from "@/plugins/cookie.js"
import CurrentUser from "@/models/CurrentUser.js"
import { getUserByApiKey } from "@/plugins/user_helper.js"
import {trans} from "@/plugins/i18n.js"

export default {
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {
        let model = {
            username: "",
            password: "",
            grant_type: "password"
        }

        let error = ref("")

        async function save(evt) {
            evt.preventDefault()
            store.loading = true
            store.error = ""
            let formData = new FormData();
            let list = []

            Object.keys(model).forEach(function (key) {
                formData.append(key, model[key]);
                list.push(key + '=' + encodeURIComponent(model[key]))
            })
            let url_encoded = list.join("&")

            try {
                let response = await fetch(store.api_url + "/session/token", {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json"
                    },
                    body: url_encoded
                })
                

                let data = await response.json()
                if (!data || !data.hasOwnProperty("access_token")) {
                    error.value = trans("login.wrong_credentials")
                } else {
                    let currentUser = await getUserByApiKey(data.access_token)
                    if (currentUser){
                        setCookie("antispam", data.access_token)
                        setCookie("antispam_original", "")
                        store.api_key = data.access_token
                        store.api_key_original = ""
                        store.current_user = currentUser
                        store.current_view = store.current_user_default_view

                    } else {
                        error.value = "No user found"
                    }
                }
            } catch (err) {
                error.value = err
            }
            store.loading = false
        }

        return {
            error,
            model,
            save,
            trans,
            store
        }
    },
    template: html,
    components: {}
}