import { ref, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import {trans} from "@/plugins/i18n.js"
import store from "@/plugins/store.js"

const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    template: html,
    props: ['error', 'name', 'type', 'disabled', 'modelValue'],
    emits: ['update:modelValue'],
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {

        const class_active = computed(function(){
            return model.value ? 'ative':'inactive'
        })
        const switch_label = computed(function(){
            if ("checkbox" == props.type){
                if (props.disabled){
                    return trans('settings_row.disabled_label')
                } else if (model.value){
                    return trans('settings_row.yes_label')
                } else {
                    return trans('settings_row.no_label')
                }
            } else {
                if (props.disabled){
                    return trans('settings_row.disabled_label')
                } else {
                    return ""
                }
            }
        })
        const class_row = computed(function(){
            return props['type']
        })
        const has_error = computed(function(){
            return typeof(props['error'] == "string") && "" != props["error"]
        })
        const infos_shown = ref(false)
        const model = computed({
            get() {
              return props.modelValue
            },
            set(value) {
              emit('update:modelValue', value)
            }
          })
        const toggleInfosShown = function(evt){
            evt.preventDefault()
            infos_shown.value = !infos_shown.value

        }
        const switch_class = computed(function(){
            return store.mobile ? "switch-no-text" : "switch-text" 
        })
        
        return {
            class_active,
            class_row,
            has_error,
            infos_shown,
            model,
            trans,
            toggleInfosShown,
            switch_class,
            switch_label
        }
    }
}
