class Account {
    constructor() {
        this.account = "";
        this.domain = "";
        this.recipientsperhour = 0;
        this.recipientsperday = 0;
        this.batv = true;
        this.spamdisabled_status = true;
        this.datasource = "";
        this.ou = "";
        this.parent_ou = "";
    }

    homogenize = function(){
        // nothing to do right now
    }
}

export default Account;