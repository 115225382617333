class WbEntry {
    constructor(){
        this.id =  0;
        this.account = "";
        this.sender = "";
        this.wb = "";
        this.source = "";
    }
    homogenize = function(){
        if (this.wb == "w"){
            this.wb = "W"
        }
        if (this.wb == "b"){
            this.wb = "B"
        }

    }
}

export default WbEntry; 