import {defineStore, createPinia, setActivePinia} from "pinia"
import CurrentUser from "@/models/CurrentUser.js"
import { setCookie, getCookie } from "@/plugins/cookie.js"
import { getUserByApiKey } from "@/plugins/user_helper.js"
const app_theme = import.meta.env.VITE_APP_THEME
const ENV = import.meta.env

export const mainStore = defineStore("main", {
    state: () => ({
        app_theme: app_theme,
        api_url: "https://api.mail.iway.ch/api/v1",
        //api_url: "https://mail-api.sisvl2.iway.ch/api/v1",
        api_key: "",
        api_key_original: "", // Parent user
        current_view: "Loading",
        current_user: new CurrentUser(),
        error: "",
        loading: false,
        mobile: false,
        env_dev: ENV.DEV,
        env_prod: ENV.PROD,
        show_staff_values: false,
        settings_swissmail: "",
    }),
    getters: {
        current_user_default_view(state) {
            if (!state.current_user.session_id || !state.current_user.account){
                return "Login"
            }
            if (state.current_user.access_level == "admin" && state.current_user.access_scope == "global"){
                return "Accounts"
            }
            return "Settings"
        },
        is_impersonated(state){
            return state.current_user.impersonated_by != null
        },
        assets_url(state){
            if ("iway" == state.app_theme){
                return "https://www.iway.ch/wp-content/themes/iwaytwo/assets/images/antispam"
            } else {
                return ""

            }
        },
        allow_impersonate(state){
            return state.current_user.access_level == 'admin'
        },
        allow_settings(state){
            return state.current_user.access_scope == 'user' || state.current_user.access_scope == 'domain'
        },
        allow_wblist(state){
            return state.current_user.access_scope == 'user' || state.current_user.access_scope == 'domain'
        },
        allow_quarantine(state){
            return state.current_user.allow_quarantine
        },
        
    },
    actions: {
        async impersonateAs(new_api_key){
            let api_key_original = this.api_key
            let access_scope_orignal = this.current_user.access_scope
            let newUser = await getUserByApiKey(new_api_key)
            if (newUser){
                if (!this.is_impersonated){
                    // Nur wenn noch nicht impersonated. Ansonsten Original-User beibehalten
                    // (im Normalfall ldap)
                    // Logout führt also zurück zum LDAP-User
                    setCookie("antispam_original", api_key_original)
                    this.api_key_original = api_key_original
                }
                setCookie("antispam_original_access_scope", access_scope_orignal)
                if ("global" == access_scope_orignal){
                    this.show_staff_values = true
                }
                setCookie("antispam", new_api_key)
                this.api_key = new_api_key
                this.current_user = newUser
            }
        },

        async logout(){
            if (this.is_impersonated){
                let new_api_key = getCookie("antispam_original")
                let currentUser = await getUserByApiKey(new_api_key)
                if (currentUser){
                    setCookie("antispam", new_api_key)
                    setCookie("antispam_original", "")
                    setCookie("antispam_original_access_scope", "")
                    this.show_staff_values = false
                    this.api_key = new_api_key
                    this.api_key_original = ""
                    this.current_user = currentUser
                } else {
                    setCookie("antispam", "")
                    setCookie("antispam_original", "")
                    setCookie("antispam_original_access_scope", "")
                    this.show_staff_values = false
                    this.api_key = ""
                    this.api_key_original = ""
                    this.current_user = new CurrentUser()
                }
            } else {
                setCookie("antispam", "")
                setCookie("antispam_original", "")
                setCookie("antispam_original_access_scope", "")
                this.show_staff_values = false
                this.api_key = ""
                this.api_key_original = ""
                this.current_user = new CurrentUser()
            }
        },
        setMobile: function(){
            let w = window.innerWidth
            this.mobile = w <= 576
        }
    }
    
})

// Here you create a standalone pinia
setActivePinia(createPinia());
const store = mainStore()
export default store