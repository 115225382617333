import { createI18n } from 'vue-i18n'
import en_messages from '@/locales/en.json'
import de_messages from '@/locales/de.json'
import fr_messages from '@/locales/fr.json'
import it_messages from '@/locales/it.json'


const i18n = createI18n({
  legacy: false,
  locale: 'de', // set locale
  fallbackLocale: 'de', // set fallback locale
  messages: {
      en: en_messages,
      de: de_messages,
      fr: fr_messages,
      it: it_messages,
    }, // set locale messages
})

const trans = i18n.global.t 

//export default i18n
export { trans, i18n};
