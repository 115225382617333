// Mit vue3's defineCustomElement
// https://vuejs.org/guide/extras/web-components.html#building-custom-elements-with-vue
// use gibt es anscheinend nicht in customElement
/*import { defineCustomElement  } from 'vue'
import './style.css'
import App from './App.vue'

let MyVueElement = defineCustomElement(App)
customElements.define('antispam-frontend', MyVueElement)
*/


// mit vue3-webcomponent-wrapper
// https://www.npmjs.com/package/vue3-webcomponent-wrapper

import { createApp, h } from "vue";
import wrapper from "vue3-webcomponent-wrapper";
import App from './App.vue'

const CustomElement = wrapper(App, createApp, h);
window.customElements.define("antispam-frontend", CustomElement);
