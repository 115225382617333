// Conditional Import
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
import store from "@/plugins/store.js"
import {trans, i18n} from "@/plugins/i18n.js"
import {computed, ref} from "vue"
import {setCookie} from "@/plugins/cookie.js"
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
//let html = eval("html_"+app_theme)

export default {
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {
        const assets_url = computed(function(){
            return store.assets_url
        })
        const language_choices = [
            {
                value: "de",
                text: trans("global.de")
            },
            {
                value: "en",
                text: trans("global.en")
            },
            {
                value: "fr",
                text: trans("global.fr")
            },
            {
                value: "it",
                text: trans("global.it")
            },
        ]

        
        const user_choices = computed(function(){
            let choices = []
            if (is_logged_in.value){
                if (is_impersonated.value){
                    choices.push({
                        value: store.current_user.account,
                        text: trans("nav.impersonated_as") +" " + store.current_user.account
                    })
                } else {
                    choices.push({
                        value: store.current_user.account,
                        text: store.current_user.account
                    })
                }
                choices.push({
                        value: "logout",
                        text: trans("nav.logout_label")
                    }
                )
            }
            return choices
        })

        const current_view = computed(function(){
            return store.current_view

        })

        const current_language = computed(function(){
            let code = i18n.global.locale.value
            return language_choices.filter(function(item){
                return item.value == code
            })[0]
        })

        const current_user = computed(function(){
            let code =  store.current_user.account
            return user_choices.value.filter(function(item){
                return item.value == code
            })[0]
        })
        

        const navbar_show = ref(false)

        const toggleNavbarShow = function(){
            navbar_show.value = !navbar_show.value
        }

        const setView = function(name){
            if (isDisabled(name)){
                return
            }
            navbar_show.value = false
            store.current_view = name
        }
        const is_logged_in = computed(function(){
            return store.api_key != "";
          })
        const is_impersonated = computed(function(){
            return store.is_impersonated
        })

        const getClass = function(name){
            let classes = []
            if (isDisabled(name)){
                classes.push('disabled')
            }
            if (isActive(name)){
                classes.push('active')
            }
            return classes.join(" ")
        }
        const isDisabled = function(name){
            
            if ('Settings' == name && !store.allow_settings){
                return true
            }
            if ('WblistPage' == name && !store.allow_wblist){
                return true
            }
            if ('Quarantine' == name && !store.allow_quarantine){
                return true
            }
            if ('Accounts' == name && !store.allow_impersonate){
                return true
            }
            return false
        }

        const isActive = function(name){
            return store.current_view == name
        }
        const setLanguage = function(val){
            i18n.global.locale.value = val
            navbar_show.value = false
        }
        const setUser = async function(new_user){
            if ("logout" == new_user){
                await store.logout()
                store.current_view = store.current_user_default_view
            }
            let first_choice = user_choices.value[0]
            /*if (first_choice){
                el.value = first_choice.value
            }*/
            navbar_show.value = false
        }

        return {
            assets_url,
            language_choices,
            current_language,
            current_user,
            current_view,
            setView,
            setLanguage,
            trans,
            is_logged_in,
            isDisabled,
            isActive,
            getClass,
            navbar_show,
            user_choices,
            setUser,
            toggleNavbarShow,
            store
            
        }
    },
    template: html,
    components: {  }
}