import { ref, onMounted, reactive, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import QuarantineMeta from "@/models/QuarantineMeta.js"
import QuarantineRowActions from "@/components/QuarantineRowActions/index.js"
import QuarantineRowHeader from "@/components/QuarantineRowHeader/index.js"
import QuarantineRowMessage from "@/components/QuarantineRowMessage/index.js"
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: {
        entry: Object, 
        selected: Boolean
    },
    emits: ['removed', 'selection'],
    components: {QuarantineRowActions, QuarantineRowHeader, QuarantineRowMessage},
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        const details_show = ref(false)
        const removed = function(){
            emit('removed')
        }
        const flag_class = computed(function(){
            if (1 == props.entry.flag_forward){
                return "flag_forward"
            } else if (1 == props.entry.flag_delete){
                return "flag_delete"
            } else if (1 == props.entry.flag_read){
                return "flag_read"
            } else {
                return "flag_notread"
            }
        })

        const updateSelection = function(evt){
            emit('selection', props.entry.id, evt.target.checked)
        }
        
        return {
            trans,
            details_show,
            flag_class,
            removed,
            updateSelection
        }
    }
}