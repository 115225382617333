import store from "@/plugins/store.js"
import {i18n} from "@/plugins/i18n.js"

let api = async function (method, path, data, headers) {
    let api_url = store.api_url;
    api_url += path
    if (!headers){
        headers = {
            "Content-Type": "application/json",
            "Accept": "application/json", 
        }
    }
    headers["Authorization"] = "Bearer " + store.api_key
    headers["Accept-Language"] =  i18n.global.locale.value
    const response = await fetch(api_url, {
        method: method,
        mode: "cors",
        //cache: "no-cache",
        //credentials: "same-origin",
        headers: headers,
        body: data == null || undefined? null : JSON.stringify(data),
    })
    return response
}

export default api
