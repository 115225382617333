import { ref, onMounted, reactive, computed, watch } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
import WbEntry from "../../models/WbEntry"

export default {
    props: ['entry', 'selected', 'list_type'],
    emits: ['reload', 'selection_toggled', 'selection_clear'],
    setup(props, { attrs, slots, emit, expose }) {
        let mod = new WbEntry()
        let orig = new WbEntry()
        if (props.entry) {
            Object.assign(mod, props.entry);
            Object.assign(orig, props.entry);
        } else {
            mod.source = "new";
            mod.account = store.current_user.account;
            mod.wb = 'B'
            if ("global" == props.list_type && "admin" == store.current_user.access_level && "domain" == store.current_user.access_scope){
                mod.account = "@" + store.current_user.domain;
            }
        }
        const errors = ref({});
        const model = reactive(mod)
        const original = reactive(orig)
        
        const account = computed(function(){
            return store.current_user.account
        })
        const current_state = computed(function(){
            if (is_whitelist.value){
                return trans('wblist_entry.is_whitelisted')
            } else {
                return trans('wblist_entry.is_blacklisted')
            } 
        })
        const current_soft_state = computed(function(){
            if (is_whitelist.value && is_soft.value){
                return trans('wblist_entry.is_whitelisted_soft')
            } else if (is_whitelist.value && !is_soft.value){
                return trans('wblist_entry.is_whitelisted_soft_inactive')
            } else if (is_blacklist.value && is_soft.value){
                return trans('wblist_entry.is_blacklisted_soft')
            } else if (is_blacklist.value && !is_soft.value){
                return trans('wblist_entry.is_blacklisted_soft_inactive')
            } 
            return ""
        })
        const disabled = computed(function(){
            if ("global" == props.list_type 
                && store.current_user.access_level == "admin" 
                && store.current_user.access_scope == "domain"){
                return false;
            }
            if ("contacts" == props.list_type){
                return true;
            }
            return model.account != account.value;
        })
        const formId = computed(function(){
            return "form"+model.id;
        })
        const has_errors = computed(function(){
            return 0 < Object.keys(errors.value).length
        })
        const is_whitelist = computed(function(){
            let res = -1 < ["w", "W", "-4"].indexOf(model.wb)
            return res
        })

        const is_blacklist = computed(function(){
            let res = -1 < ["b", "B", "4"].indexOf(model.wb)
            return res
        })

        const is_soft = computed(function(){
            let res = -1 < ["4", "-4"].indexOf(model.wb)
            return res
        })

        const is_dirty = computed(function(){
            return JSON.stringify(original) != JSON.stringify(model)
        })

        const setBlacklist = function(){
            model.wb = "B"  
        }

        const setBlacklistSoft = function(){
            model.wb = "4"  
        }

        const setWhitelist = function(){
            model.wb = "W"
        }
        const setWhitelistSoft = function(){
            model.wb = "-4"  
        }

        const toggleIsWhitelist = function(){
            if (is_whitelist.value){
                if(is_soft.value){
                    setBlacklistSoft()
                } else {
                    setBlacklist()
                }
            } else {
                if(is_soft.value){
                    setWhitelistSoft()
                } else {
                    setWhitelist()
                }
            }
        }
        const toggleSoft = function(){
            if (is_whitelist.value){
                if (is_soft.value){
                    setWhitelist()
                } else {
                    setWhitelistSoft()
                }
            } else {
                if (is_soft.value){
                    setBlacklist()
                } else {
                    setBlacklistSoft()
                }
            }
        }

        

        /*const switch_class = computed(function(){
            return store.mobile ? "switch-no-text" : "switch-text" 
        })*/


        async function save(evt){
            evt.preventDefault()
            let data = Object.assign({}, model);
            store.error = ""
            errors.value = {}
            let account_name = account.value
            if ("global" == props.list_type){
                account_name = `@${store.current_user.domain}`
            }
            try {
                let response = null
                let is_new = 0 == model.id;
                if (is_new){
                    delete(data["id"])
                    delete(data["source"])
                    delete(data["account"])
                    response = await api("POST", `/accounts/${encodeURIComponent(account_name)}/wblist`, data);

                } else {
                    response = await api("PATCH", `/accounts/${encodeURIComponent(account_name)}/wblist/${model.id}`, data);
                }
                if (200 <= response.status && 400 > response.status){
                    Object.assign(original, model)
                    if (is_new){
                        model.sender = "";
                    }
                    emit('reload')
                } else {
                    let json = await response.json();
                    if (typeof(json) == "object"){
                        errors.value = json
                    } else {
                        store.error = await response.text();
                    }
                    
                }
            } catch (err) {
                store.error = err

            }
        }

        function clearErrors(){
            errors.value = {}
        } 

        watch(is_dirty, function(){
            clearErrors();
            emit('selection_clear')
        })
        
        return {
            current_state,
            current_soft_state,
            emit,
            errors,
            disabled,
            formId,
            model, 
            has_errors,
            is_blacklist,
            is_whitelist,
            is_soft,
            is_dirty,
            save,
            setBlacklist,
            setWhitelist,
            toggleIsWhitelist,
            toggleSoft,
            trans,
            clearErrors
        }
    },
    template: html
}