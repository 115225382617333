class QuarantineMeta {
    constructor() {
        this.id = "";
        this.timestamp = "";
        this.transaction_id = "";
        this.server = "";
        this.localip = "";
        this.account = "";
        this.mailfrom = "";
        this.rcptto = "";
        this.subject = "";
        this.sa_spam = "";
        this.sa_score = "";
        this.sa_symbols = "";
        this.expurgate_spam = "";
        this.expurgate_score = "";
        this.total_spamscore = "";
        this.classification = "";
        this.action = "";
        this.attachments = [];
        this.size = 0;
        this.release_timestamp = null;
        this.release_to = null;
        this.release_result = null;
    }

    homogenize = function(){
        // Nothing to do
        this.timestamp = this.timestamp.replace('T', ' ')
        this.timestamp = this.timestamp.replace('.000', '')

    }
}

export default QuarantineMeta;