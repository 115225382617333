class CurrentUser {
    constructor(){
        this.access_level = ""
        this.access_scope = ""
        this.account = ""
        this.allow_quarantine = false
        this.allow_spam_address_extension = true
        this.datasource = ""
        this.domain = ""
        this.impersonated_by = ""
        this.method = ""
        this.server = ""
        this.session_id = 0

        // ob das lesen der Quarantäne  flag_read setzt oder nicht
        // Wird false sein wenn impersonated
        this.quarantine_flag_read = true

        // Transport wird nicht immer geliefert
        this.transport = ""
    }
}

export default CurrentUser