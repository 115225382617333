import { ref, onMounted, reactive, computed } from "vue"
import {trans} from "@/plugins/i18n.js"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: ['modelValue', 'length'],
    emits: ['update:modelValue'],
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        const page = computed({
            get() {
              return props.modelValue
            },
            set(value) {
              emit('update:modelValue', value)
            }
          })
        const page_number_list = computed(function(){
            let list = []
            for (var p=1; p<=props.length; p++){
                if (props.length <= 5){
                    list.push(p)
                } else {
                    if (1 == p){
                        // Erste immer sichtbar
                        list.push(p)
                    } else if (props.length == p){
                        // letzte immer sichtbar
                        list.push(p)
                    } else if (p == page.value || p == page.value -1 || p == page.value + 1){
                        // seite vor und hinter page ist sichtbar
                        list.push(p)
                    } else if (page.value < 4 && p <= 4){
                        list.push(p)
                    } else if (page.value > (props.length - 3) && p >= (props.length -3)){
                        list.push(p)
                    } else if (2 == p) {
                        // an zweiter Stelle Punkte
                        list.push('...')
                    } else if (p == props.length -1) {
                        // an zweitletzer Stelle Punkte
                        list.push('...')
                    }
                }
            }
            return list
        })
        async function setPage(p){
            page.value = parseInt(p)
        }
        async function movePage(d){
            let diff = parseInt(d)
            if (-1 == diff && page.value > 1){
                page.value = page.value - 1
            }
            if (1 == diff && page.value < props.length){
                page.value = page.value + 1
            }
        }

        return {
            setPage,
            movePage,
            page,
            page_number_list,
            trans
        }

    }
}