<template>
  <div id="antispam-frontend" :class="wrap_class">
    <Navigation />
    
      <Settings v-if="logged_in && 'Settings' == current_view" />
      <WblistPage v-if="logged_in && 'WblistPage' == current_view" />
      <Accounts v-if="logged_in && 'Accounts' == current_view" />
      <Quarantine v-if="logged_in && 'Quarantine' == current_view" />
      <Login v-if="'Login' == current_view" />
    <footer v-if="'swissmail' != app_theme">
      <div class="copyright"  v-html="trans('global.copyright')"></div>
    </footer> 
  </div>
  <Loading />
  <Error />
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import { getCookie } from "@/plugins/cookie.js"
import store from "@/plugins/store.js"
import { setCookie } from "@/plugins/cookie.js"
import { trans, i18n } from "@/plugins/i18n.js"
import { getUserByApiKey } from "@/plugins/user_helper.js"

// components
import Accounts from '@/components/Accounts/index.js'
import CurrentUser from "@/models/CurrentUser.js"
import Error from '@/components/Error/index.js'
import Login from '@/components/Login/index.js'
import Navigation from '@/components/Navigation/index.js'
import Settings from '@/components/Settings/index.js'
import Quarantine from '@/components/Quarantine/index.js'
import WblistPage from '@/components/WblistPage/index.js'
import Loading from '@/components/Loading/index.js'

const props = defineProps({
  dataLanguage: String,
  dataSettings_swissmail: String
})

const current_view = computed(function () {
  return store.current_view
})

const logged_in = computed(function () {
  let res = typeof (store.api_key) == "string" && store.api_key != "";
  return res
})
const app_theme = computed(function(){
  return store.app_theme
})

const wrap_class = computed(function(){
  return 'swissmail' == app_theme.value
    ? 'skin-swissmail'
    : ''
})
onMounted(async function () {
  let token = getCookie("antispam")
  let original_token = getCookie("antispam_original")
  let antispam_original_access_scope = getCookie("antispam_original_access_scope")
  let user = null
  if (token) {
    user = await getUserByApiKey(token)
  }
  let currentUser = new CurrentUser()
  if (user) {
    Object.assign(currentUser, user)
  }
  if (!currentUser.account) {
    store.api_key = ""
    store.api_key_original = ""
    setCookie("antispam", "")
    setCookie("antispam_original", "")
  } else {
    store.api_key = token
    store.api_key_original = original_token
  }
  store.current_user = currentUser
  store.current_view = store.current_user_default_view
  store.show_staff_values = "global" == antispam_original_access_scope
  store.setMobile()
  window.addEventListener("resize", function(){
    store.setMobile()
  })
  let language = props.dataLanguage

    if (-1 < ["de", "en", "fr", "it"].indexOf(language)){
      i18n.global.locale.value = language
    } 

  // übergabe von Swissmail-Settings
  // account_function=account|forward
  store.settings_swissmail = props.dataSettings_swissmail || ""


  // Place Stylesheet inside the shadowRoot
  // not necessary with vue3-webcomponent-wrapper
  /*let els = document.getElementsByTagName('antispam-frontend')
  for (var i=0; i<els.length;i++){
    let link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', "https://www-copy.iway.ch/wp-content/themes/iwaytwo/assets/css/antispamfront.css?t=" + (new Date()).getTime());
    els[i].shadowRoot.appendChild(link)
  }*/
  

})
</script>



