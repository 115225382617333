import { ref, onMounted, reactive, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import QuarantineMeta from "@/models/QuarantineMeta.js"
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: ['entry'],
    emits: [],
    template: html,
    setup(props, { attrs, slots, emit, expose }) {
        const account = computed(function(){
            return store.current_user.account;
        })

        const header = ref("")
        const message = ref("")
        const load = async function() {
            let hds = {
                "Content-Type": "text/plain",
                "Accept": "text/plain", 
            }
            try {
                let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${props.entry.id}/header`
                let res = await api("GET", url, null, hds);
                header.value = await res.text();
            } catch (err) {
                store.error = err
            }
            try {
                let url = `/accounts/${encodeURIComponent(account.value)}/quarantine/${props.entry.id}/preview`
                
                let res = await api("GET", url, null, hds);
                message.value = await res.text();
            } catch (err) {
                store.error = err
            }
        }

        onMounted(load);
        
        return {
            trans,
            message,
            header
        }
    }
}