import { ref, onMounted, reactive, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}
import Wblist from '@/components/Wblist/index.js'
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"

export default {
    components: {
        Wblist
    },
    setup(props, { attrs, slots, emit, expose }) {

        const tab = ref("personal") // global, source

        const classActive = function(tabname){
            if (tabname == tab.value){
                return "active"
            } 
        }

        const setTab = function(tabname){
            tab.value = tabname;
        }

        return {
            tab,
            setTab,
            classActive,
            trans,
            store
        }
    },
    template: html

}