class Settings {
    constructor() {
        this.id = 0
        this.account = ""
        this.active = false
        this.rbl_filter_enabled = false
        this.greylist_filter_enabled = false
        this.dmarc_filter_enabled = false
        this.spf_filter_enabled = false
        this.virus_filter_enabled = false
        this.spam_filter_enabled = false
        this.attachment_filter_enabled = false
        this.spam_tag_level = 0
        this.spam_quarantine_level = 0
        this.spam_cutoff_level = 0
        this.spam_modifies_subject = false
        this.spam_subject_tag = ""
        this.virus_quarantine_enabled = false
        this.spam_quarantine_enabled = false
        this.attachment_quarantine_enabled = false
        this.spam_address_extension = null // string
        // this.unchecked_subject_tag = "" /* SPAM-25, weglassen */
        //this.virus_address_extension = null // string /* SPAM-25, weglassen */
        //this.header_quarantine_enabled = false /* SPAM-25, weglassen */
        //this.header_filter_enabled = false /* SPAM-25, weglassen */
        //this.attachment_address_extension = null // string /* SPAM-25, weglassen */
        //this.header_address_extension = null // string /* SPAM-25, weglassen */
    }
}

export default Settings