import { ref, onMounted, reactive, watch, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import api from "@/plugins/api.js"
import Settings from "@/models/Settings.js"
import SettingsRow from "@/components/SettingsRow/index.js"
import store from "@/plugins/store.js"
import {trans, i18n} from "@/plugins/i18n.js"

const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme) {
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    components: {
        SettingsRow
    },
    // setup(props, context) -> context hat attrs,slots,emit,expose
    setup(props, { attrs, slots, emit, expose }) {
        const account = computed(function(){
            return store.current_user.account
        })
        const loading = ref(true);
        const model = reactive(new Settings());
        const errors = ref({});
        const saved = ref(false);
        const use_default_settings = ref(null)

        const page_subtitle = computed(function(){
            if (use_default_settings.value){
                return trans('settings.page_subtitle_global')
            } else {
                return trans('settings.page_subtitle_personal')
            }
        })

        const show_staff_values = computed(function(){
            return store.show_staff_values
        })
        const staff_values = computed(function(){
            return `Transport: ${store.current_user.transport}<br/> Datasource: ${store.current_user.datasource}`
        })

        async function load() {
            store.error = ""
            try {
                let res = await api("GET", `/accounts/${encodeURIComponent(account.value)}/antispam?show-effective=true`, null)
                let json = await res.json()
                Object.assign(model, json)
            } catch (err) {
                store.error = err
            }
            use_default_settings.value = account.value != model.account;
            loading.value = false;
        }

        async function save(evt) {
            store.error = ""
            errors.value = {}
            evt.preventDefault()
            saved.value = false;
            if (use_default_settings.value){
                return;
            }
            store.loading = true
            let data = Object.assign({}, model);
            delete(data["active"]);
            let res = await api("PATCH", `/accounts/${encodeURIComponent(account.value)}/antispam`, data);  
            if (400 == res.status){
                let json = await res.json();
                if (typeof(json) == "object"){
                    errors.value = json
                } else {
                    store.error = await res.text()
                }
                
            } else if (200 <= res.status && 400 > res.status){
                saved.value = true;
                window.setTimeout(function(){
                    saved.value = false;
                }, 2000)
            } else {
                let json = await res.json();
                if (json.hasOwnProperty("detail")){
                    store.error = json.detail;
                } else {
                    store.error = await res.text();
                }
            }
            store.loading = false
            
        }
        

        async function onUseDefaultSettingsChange(newValue, oldValue){
            errors.value = {}
            store.error = ""
            if (null == oldValue || newValue == oldValue){
                // Initialization
                return;
            }
            store.loading = true
            // Nach Änderung load() aufrufen weil effective und personal verändert sind
            try {
                let data = {
                    active: newValue == true ? false : true
                }
                let res = await api("PATCH", `/accounts/${encodeURIComponent(account.value)}/antispam`, data);
                if (404 == res.status){
                    // create new
                    let res2 = await api("POST", `/accounts/${encodeURIComponent(account.value)}/antispam`, data)
                }
            } catch (err){
                store.error = err
                store.loading = false
                return
            }
            store.loading = false
            await load()
        }

        const fieldError = computed(function(){
            return function(name){
                if (errors.value.hasOwnProperty(name) && Array.isArray(errors.value[name])) {
                    return errors.value[name].join("<br/>")
                }
                return ""
            }
        })

        const allow_spam_address_extension = computed(function(){
            let val = store.current_user.allow_spam_address_extension
            return val
        })

        

        watch(use_default_settings, onUseDefaultSettingsChange)
        onMounted(load)

        return {
            allow_spam_address_extension,
            loading,
            fieldError,
            model,
            save,
            saved,
            show_staff_values,
            staff_values,
            trans,
            use_default_settings,
            page_subtitle,
        }
    },
    template: html
}