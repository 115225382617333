import { ref, onMounted, reactive, computed } from "vue"
import html_iway from "./template.iway.html?raw"
import html_swissmail from "./template.swissmail.html?raw"
import QuarantineMeta from "@/models/QuarantineMeta.js"
import api from "@/plugins/api.js"
import store from "@/plugins/store.js"
import {trans} from "@/plugins/i18n.js"
const app_theme = import.meta.env.VITE_APP_THEME
let html = ""
if ("iway" == app_theme){
    html = html_iway
} else if ("swissmail" == app_theme){
    html = html_swissmail
}

export default {
    props: ['entry'],
    emits: [],
    template: html,
    setup(props, { attrs, slots, emit, expose }) {

        const header_list_left = [
            {
                label: trans("quarantine_row_header.mailfrom"),
                value: props.entry.mailfrom
            },
            {
                label: trans("quarantine_row_header.rcptto"),
                value: props.entry.rcptto
            },
            {
                label: trans("quarantine_row_header.subject"),
                value: props.entry.subject
            },
            {
                label: trans("quarantine_row_header.size"),
                value: props.entry.size
            },
            
            {
                label: trans("quarantine_row_header.timestamp"),
                value: props.entry.timestamp
            },
            {
                label: trans("quarantine_row_header.id"),
                value: props.entry.id
            },
            {
                label: trans("quarantine_row_header.transaction_id"),
                value: props.entry.transaction_id
            },
            {
                label: trans("quarantine_row_header.server"),
                value: props.entry.server
            },
            {
                label: trans("quarantine_row_header.localip"),
                value: props.entry.localip
            },
            

        ]

        const header_list_right = [
            {
                label: trans("quarantine_row_header.sa_spam"),
                value: props.entry.localip
            },
            {
                label: trans("quarantine_row_header.sa_score"),
                value: props.entry.sa_score
            },
            {
                label: trans("quarantine_row_header.sa_symbols"),
                value: props.entry.sa_symbols
            },
            {
                label: trans("quarantine_row_header.expurgate_spam"),
                value: props.entry.expurgate_spam
            },
            {
                label: trans("quarantine_row_header.expurgate_score"),
                value: props.entry.expurgate_score
            },
            {
                label: trans("quarantine_row_header.expurgate_category"),
                value: props.entry.expurgate_category
            },
            {
                label: trans("quarantine_row_header.total_spamscore"),
                value: props.entry.total_spamscore
            },
            {
                label: trans("quarantine_row_header.classification"),
                value: props.entry.classification
            },
            {
                label: trans("quarantine_row_header.action"),
                value: props.entry.action
            },
            
            
        ]
        
        return {
            trans,
            header_list_left,
            header_list_right,
        }
    }
}